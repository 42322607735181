import React from 'react'

export default function Eventcard(props){ 
    return (
        <div className='container max-w-s px-5 py-2 my-1  '>
            
            <div className='eventDate'>
                <div className='my-2 rounded-full text-2xl text-hellblau font-bold text-s '>
                {props.date}
                </div>
            </div>

            <div className='eventTitle'>
                <button className=' text-white font-bold px-4 py-2 my-1 rounded-lg bg-gradient-to-r from-lila to-pink'>
                {props.title}
                </button>
                </div>

                <div className='grid grid-cols-2 gap-4 '>
            <div className='eventLocation'>
                <div className='px-1 py-1  mx-1 my-1 rounded-full  text-lila font-bold text-xs'>
                {props.location}
                </div>
            </div>
        
            <div className='eventPlatform'>
                <div className='px-1 py-1  mx-1 my-1 rounded-full  text-lila font-bold text-xs'>
                {props.platform}
                </div>
            </div>
        
            </div>

           <div className='eventTeaser'>
            <p className='mt-2 text-sm'>{props.teaser}</p>
            </div>
        
            
    
        </div>
    )
}


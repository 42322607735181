import React from "react";
import { Link } from "react-router-dom";

export default function Navbar(){
    return(
        <div className='h-16 pb-2 pt-2 text-center text-lg font-bold divide-x-2 divide-white'>

          <Link to='/home' onClick={toTop} className='h-12 w-1/4 float-left rounded-lg '>
            <h1 className="mt-3 md:mt-2 font-montserrat text-sm md:text-lg text-lila hover:text-gruen border-lila hover:border-gruen border-2 rounded-lg">
              Startseite</h1>
          </Link>

          <Link to='/werwirsind' onClick={toTop} className=' h-12 w-1/4 float-left rounded-lg '>
          <h1 className="mt-3 md:mt-2 font-montserrat text-sm md:text-lg text-lila hover:text-orange border-lila hover:border-orange border-2 rounded-lg">
            Wer wir sind</h1>
          </Link>

          <Link to='/waswirtun' onClick={toTop} className=' h-12 w-1/4 float-left rounded-lg '>
          <h1 className="mt-3 md:mt-2 font-montserrat text-sm md:text-lg text-lila hover:text-hellblau border-lila hover:border-hellblau border-2 rounded-lg">
            Was wir tun</h1>
          </Link>

          <Link to='/machmit' onClick={toTop} className=' h-12 w-1/4 float-left rounded-lg '>
          <h1 className="mt-3 md:mt-2 font-montserrat text-sm md:text-lg text-lila hover:text-gelb border-lila hover:border-gelb border-2 rounded-lg">
            Sei dabei!</h1>
          </Link>

          </div>
    );
}
const toTop = () => {
  window.scrollTo(0, 0);
};
import React from "react";
import '../App.css';
import Navbar from "../components/navbar";
import LogoHead from "../components/logoHead";
import Footer from "../components/footer";
import Impressumtxt from "../assets/textcontent/impressumtext";

export default function Impressum(){
    return(
        <div>
        <LogoHead/>
        <Navbar/>
        <div className=" mt-16 mx-14 lg:mr-64 text-justify">
            <Impressumtxt/>

        </div>
        <Footer/>
        </div>
    );
}
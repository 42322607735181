import React from "react";
import { WerWirSind, arbeitsgruppen } from "../../assets/textcontent/werwirsind";
import Sind from "./sind";

export default function WirSind(){
    return(
        <div className="font-montserrat grid">
      
        <ul className='content flex flex-wrap justify-center '> 
         {arbeitsgruppen.map((werwirsind, index) => (
          <li key={index} className='max-w-xs sm:m-2 p-4 rounded-3xl border-0 border-lila hover:bg-slate-50'>
              <Sind {...werwirsind}/>
          </li>
         ))}  
        </ul>
      </div>
    )
}



export const Eventcontent = [
    
    {
        date:"21.2.2024",
        time:"16:00",
        title: "Meeting",
        teaser: "Zwischenstand der Studienleistung",
        description:"Die lieben Ameisen finden sich zusammen und teilen den Zwischenstand mit.",
        location:"Discord",
        platform:"online",
        src:"/event/1",
        link:"/events/meeting"
    },

    {
        date:"21.2.2024",
        time:"19:00",
        title: "Versammlung",
        teaser: "Offizielle Mitgliederversammlung.",
        description:"Unsere erste Mitgliederversammlung als offiziell eingetragener Verein steht an! Berichte des Vorstands, der Revision und Vorstandsentlastung stehen an. drei Positionen im Vorstand werden neu gewählt, so wie das Awareness-Team und die Kassenprüfer*innen. Anschließend erfolgt ein Update der AG zur externen Kommunikation.",
        location:"Discord",
        platform:"online",
        src:"/event/2",
        link:"/events/Mitgliederversammlung"
    },

    {
        date:"16.3.2024",
        time:"15:00",
        title: "Kulturtisch",
        teaser: "Unser erster offline Kulturtisch!",
        description:"Du bist neu in der Stadt oder hast Lust einfach mal in den Verein zu schnuppern? Komm gerne vorbei, du musst kein Mitglied sein!",
        location:"Osnabrück",
        platform:"online",
        src:"/event/3",
        link:"/events/Kulturtisch"
    },

    {
        date:"31.8.2024",
        time:"19:00",
        title: "#awareness",
        teaser: "Meeting des Awareness Teams",
        description:"Das Awareness Team setzt sich zusammen und steht für eure Fragen und Rückmeldungen herzlich gerne zur Verfügung. Ihr seid kein Mitglied? Schaut trotzdem gerne rein!",
        location:"Discord",
        platform:"online",
        link:"/events/treffen_awareness"
    },
]
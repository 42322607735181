import React from "react";
import '../App.css';
import Navbar from "../components/navbar";
import LogoHead from "../components/logoHead";
import Footer from "../components/footer";
import { vereinsaufgaben } from "../assets/textcontent/homepage";
import Anmeldung from "../components/anmeldebtn";
import {einleitungstext, funktion, fonds, fond1, fond2, fond3 } from "../assets/textcontent/fundus";


export default function WasWirTun(){
    return(
        <div>
        <LogoHead/>
        <Navbar/>
        <div className="font-montserrat m-4 ml-10 mr-10 p-8 rounded-3xl border-0 border-lila">
<div className="flex flex-col md:flex-row">
        <div className="Werwirsind md:w-1/2">
            <h2 className="text-lila font-bold my-5 text-2xl">Was wir tun</h2>
            <p className="flex">{vereinsaufgaben}</p>
            <Anmeldung/>
            
            
        </div>

        <div className="Kontakte md:w-1/2">
        <h2 className="text-pink font-bold my-5 text-2xl">Fundus</h2> 
            <p>{einleitungstext}</p> <br/>

            <div className="flex flex-col md:flex-row">
            <button className="md:w-1/2">
            <a href="https://freiwilligen-fundus.de/" className="text-s text-pink font-bold px-4 py-2 my-4 inline-block rounded-lg border-pink border-2 border-l-4 border-b-4 hover:bg-pink hover:text-white">
                Fundus »</a>
            </button>
            <button className="md:w-1/2">
            <a href="https://www.betterplace.org/de/projects/108762-die-freiwilligendienste-inklusiver-und-diverser-machen-mit-dem-fundus" className="text-s text-pink font-bold px-4 py-2 my-4 inline-block rounded-lg border-pink border-2 border-l-4 border-b-4 hover:bg-pink hover:text-white">
                Spenden »</a>
            </button>
            </div>

            
        <h2 className="text-pink font-bold my-5 text-2xl">Fundus Fonds</h2> 
            <p>{fonds}</p> <br/>
            <p className="text-pink font-bold my-5 text-xl">Incoming Support.</p>
            <p>{fond1}</p> <br/>
            <p className="text-pink font-bold my-5 text-xl">Jahresstipendien</p>
            <p>{fond2}</p> <br/>
            <p className="text-pink font-bold my-5 text-xl">Notfall Fonds.</p>
            <p>{fond3}</p> <br/>

            
            
        </div>
    </div>

        </div>
        <Footer/>
        </div>
    );
}
import React from "react";
import Event from "./event";
import { Veranstaltungen } from "../assets/content/veranstaltungen";
import { Eventcontent } from "../assets/textcontent/Eventcontent";

export default function Events() {
  return (
    <div className="font-montserrat">
      <div className="event-box">
        <h2 className="text-2xl text-lila font-bold p-8">
          Terminausblick</h2>
        <div className="event-list grid">
          <ul className="content flex flex-wrap justify-center items-center">
            {Eventcontent.map((event, index) => (
              <li key={index} className="max-w-xs m-4 p-8 rounded-3xl border-0 border-lila w-64">
                <Event {...event} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import '../App.css';
import LogoHead from "../components/logoHead";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import qrcode from "../assets/img/qr-code.png";


export default function Info(){
    return(
<div>
            <LogoHead/>
            <Navbar/>
            <div className="text-left font-montserrat m-4 ml-10 mr-10 p-8 rounded-3xl border-4 border-lila">
            
<div className="flex flex-col md:flex-row text-lila text-2xl font-bold">
        <div className="">
            <img src={qrcode} className="max-h-80 " alt="QR-Code zum Discordserver"></img>
            <p className="">QR-Code zum Discordserver</p> <br/>
            <p className="">Link zum Discordserver folgt.</p> <br/>
            
        </div>

        <div className=" flex-right">
        <p>Beitrittsanträge? Link folgt.</p>
        <p  className="font-medium">Regelung zum Mitgliedsbeitrag: Für Vollmitgliedschaften frei wählbar.</p>
        <p className="font-medium"> Der Beitrag für Fördermitgliedschaft startet für Privatpersonen ab 5 € und juristische Personen ab 20 €.</p> <br/>
        </div>

</div>

            
            
        </div>
        <Footer/>
</div>


    );
}

import toTop from "./toTop"

export default function Antrag(){
    return(
        <div>
            <button>
            <a href="/info" onClick={toTop} className="text-s text-lila font-bold px-4 py-2 my-4 inline-block rounded-lg border-lila border-2 border-l-4 border-b-4 hover:bg-lila hover:text-white">
                Zum Antrag »</a>
            </button>
        </div>
    )
}
import React from "react";
import '../App.css';
import Navbar from "../components/navbar";
import LogoHead from "../components/logoHead";
import Footer from "../components/footer";
import Anmeldung from "../components/anmeldebtn.js"
import Antrag from "../components/antragbtn.js";
import {einleitung, foerdermitglied, vollmitglied} from "../assets/textcontent/mitgliedschaften.js";
import {kulturtischtext} from "../assets/textcontent/contentkulturtisch.js"
import Nextevent from "../components/next-events/Nextkulturtisch.jsx";



export default function MachMit(){
    return(
        <div>
        <LogoHead/>
        <Navbar/>

<div className="font-montserrat m-4 ml-10 mr-10 p-8 rounded-3xl border-0 border-lila">
        <h2 className="text-lila font-bold my-5 text-2xl">Unterstütze uns!</h2>
            <p className="flex">{einleitung}</p>

    <div className="flex flex-col md:flex-row">
        <div className="Voll md:w-1/2">
            <h2 className="text-lila font-bold my-5 text-2xl">Vollmitgliedschaft</h2>
            {vollmitglied}
            <Antrag/>
        </div>

        <div className="Förderung md:w-1/2">
            <h2 className="text-lila font-bold my-5 text-2xl">Fördermitgliedschaft</h2>
            {foerdermitglied}
            <Antrag/>
        </div>
    </div>

        <div className="Veranstaltungen flex-auto">
            <h2 className="text-lila font-bold my-5 text-2xl">Unsere Kulturtische</h2>
            {kulturtischtext}
            <Anmeldung/>
            <Nextevent/>
        </div>
        
    </div>

        

        <Footer/>
        </div>
    );
}

import React from "react";
import '../App.css';
import {Link} from 'react-router-dom';
import { einleitungstext, vereinsaufgaben } from "../assets/textcontent/homepage";
import Events from "../components/events";
import Navbar from "../components/navbar";
import LogoHead from "../components/logoHead";
import Footer from "../components/footer";

export default function Home(){
  return(
    <div className="font-montserrat">
      <LogoHead/>
      <Navbar/>

      <div className="font-montserrat m-4 ml-10 mr-10 p-8 rounded-3xl border-0 border-lila">
        <div className="Einleitungstext ">
          <div className="">
          <h2 className=" text-lila text-2xl font-bold">
              Willkommen zum Prototypen der Vereinswebsite für ZUGABE e. V. </h2> <br/>
              <p>{einleitungstext}</p> <br/>
              <p>{vereinsaufgaben}</p>
          </div>
            
            
        </div>

        <div className="Events">
            <Events/>
        </div>
    </div>
       
      <Footer/>
    </div>
    );
    function ButtonClick() {
        alert("Button clicked!");
      }

      
}

const toTop = () => {
  window.scrollTo(0, 0);
};
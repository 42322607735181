export const werwirsindtext =
"Unser Verein beträgt 34 Gründungsmitglieder. Die meisten sind in bestimmten Aufgabenbereichen aktiv, um den Verein weiter aufzubauen. Wir freuen uns über alle, die Interesse haben sich anzuschließen. Auf unserem Discordserver kannst du dich schon vorab einfach an die entsprechenden Kanäle wenden, oder du meldest dich bei uns per Mail.";

export const vorstand = ["Adrian, Chris, Johanna, Nina, Sonja"]
export const finanz = ["Daniel, Sharon, Johanna"]

export const arbeitsgruppen = [
    {
        kanal:"#awareness",
        kontakt:"Anna Esther Eszter",
        aufgaben:"Das Awareness-Team sind unsere Vertrauensmenschen. Sie stehen im laufendem Betrieb für die verschiedensten Anliegen bereit und erarbeiteten das Awareness-Konzept.",
    },

    {
        kanal:"#design_ci_komm_extern",
        kontakt:"Mo Nina Deniz",
        aufgaben:"Zur externen Kommunikation gehören die Erarbeitung des Corporate Designs, Erstellung von Informationsmaterial und dem Aufbau unserer Internetpräsenz.",
    },

    {
        kanal:"#fundus",
        kontakt:"Viola",
        aufgaben:"Der Fundus bildet als Plattform das Herzstück des Vereins. Hierüber laufen unsere Fonds um finanzielle Unterstützung zu ermöglichen. Durch Arbeitstreffen wie der Fundstück-Ideen-Schmiede werden die Angebote weiter ausgebaut.",
    },
    
    {
        kanal:"#mittelvergabe",
        kontakt:"Anna Viola",
        aufgaben:"Mit dem Fundus Fonds ermöglichen wir die finanzielle Unterstützung von angehenden und Ehemaligen Freiwilligen. Alles zur Vergabe dieser Spendengelder wird hier erarbeitet.",
    },

    {
        kanal:"#programm",
        kontakt:"Johanna",
        aufgaben:"Für Events und andere spannende Programmpunkte werden hier neue Impulse gegeben um unsere Vernetzungsmöglichkeiten innerhalb und außerhalb unserer FSJ-Kreise weiter aufzubauen. Hieraus entspringt der Kulturtisch als unser Stammtisch.",
    },

    {
        kanal:"#zugehörigkeit",
        kontakt:"Chris",
        aufgaben:"Vereinsmitgliedschaft oder Zugabe-Zugehörigkeit? Hier erarbeiten wir neue Begriffe für die Voll- und Fördermitgliedschaft.",
    },

]


export const einleitungstext = 
"Der Fundus ist eine Plattform mit Angeboten, Produkten, Workshops, Beratungen, Kunst, Veranstaltungen ... ehemaliger Freiwilliger*: eine Sammlung an Fundstücken! Die Angebote werden pro Bono eingebracht und können außerhalb der Plattform von von Externen erworben werden. Das Entgelt - oder ein Teil - wird dann an den Fundus-Fonds gespendet."

export const funktion = 
"Im Fundus kannst du Dienstleistungen, Produkte und die Expertise von ehemaligen* Freiwilligen suchen, finden und buchen. Damit profitierst du und/oder deine Institution nicht nur von dem Engagement und des Know-how der Ehemaligen*, sondern ermöglichst es mehr Menschen ein freiwilliges Jahr Kultur und Bildung machen zu können, die es sich sonst nicht leisten könnten und sorgst dafür, dass die Freiwilligendienste Kultur und Bildung noch inklusiver und diverser werden."

export const fonds = 
"Mit dem Fonds werden ab dem Jahrgang 2023/2024 unbürokratisch aktuelle und zukünftige Freiwillige* supported, die sich ein Jahr engagieren möchten, denen aber das Geld dafür fehlt, die in Notlagen geraten und schnelle Hilfe benötigen oder für Zuschüsse beim Incoming."

export const fond1 = 
"2 x 1 Jahr Freiwilligendienst Kultur und Bildung in Deutschland ermöglichen. Das Stipendium umfasst. u.a finanziellen Support im Visa-Verfahren + Zuschüsse zur Reise."

export const fond2 = 
"Wer nicht über genug Mittel verfügt, kann sich kein Freiwilliges Jahr leisten. Wir vergeben 2 Stipendien vor dem Start. Damit die Entscheidung für 1 Jahr Engagement bezahlbar wird."

export const fond3 = 
"Immer wieder geraten Freiwillige in Not und akute Problemlagen (z.B. juristische Hilfen, Wohnungsnot, drohende Abschiebunen, familiärer Streit ...) Der Fundus agiert unbürokratisch, schnell und will damit demütigende Erfahrungen verhindern."
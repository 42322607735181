import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Analytics } from "@vercel/analytics/react";
import { Eventcontent } from './assets/textcontent/Eventcontent';
import MachMit from './pages/MachMit';
import Home from './pages/Home';
import WerWirSind from './pages/WerWirSind';
import WasWirTun from './pages/WasWirTun';
import Datenschutz from './pages/Datenschutz';
import Impressum from './pages/Impressum';
import Info from './pages/Info';
import Eventpage from './pages/Eventpage';
import NotFound from './pages/NotFound';

function App() {
  return (
    <div className="App">
      <SpeedInsights/>
      <Analytics/>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home/>}/>
          <Route path='*' element={<NotFound/>}/>
          <Route path='/home' element={<Home/>}/>
          <Route path="/werwirsind" element={<WerWirSind/>}/>
          <Route path="/waswirtun" element={<WasWirTun/>}/>
          <Route path="/machmit" element={<MachMit/>}/>
          <Route path="/datenschutz" element={<Datenschutz/>}/>
          <Route path="/impressum" element={<Impressum/>}/>
          <Route path='/info' element={<Info/>}/>
          {Eventcontent.map(event => (
            <Route
              key={event.link}
              path={event.link}
              element={<Eventpage {...event} />}
            />
          ))}
        </Routes>
      </BrowserRouter>
    </div>
  );
}



export default App;

import React from "react";
import '../App.css';
import Navbar from "../components/navbar";
import LogoHead from "../components/logoHead";
import Footer from "../components/footer";
import { Link } from "react-router-dom";

export default function WerWirSind(){
    return(
        <div>
        <LogoHead/>
        <Navbar/>
        <div className=" font-montserrat mt-16 text-justify mx-14 lg:mr-64">
            
            <h1 className=" font-bold text-4xl text-lila">Datenschutz</h1> <br/>


            <div className=" float-left h-1"/>

            <div className=" float-left text-center">
            <h1 className="text-lila font-bold">ART. 13 DSGVO</h1> <br/>
            <h1 className="text-lila font-bold">Informationspflicht bei Erhebung von personenbezogenen Daten</h1> 
            </div>

            

            <div className=" w-1/2 float-left clear-left m-1">
            <br/>
            <h1 className=" text-lila font-semibold text-left">Angaben zum Verantwortlichen</h1> <br/>
            <h1 className="">Zugabe e.V.<br/>
            Johannes-Schlaf-Straße 10 <br/>
            39110 Magdeburg <br/>
            fsj.zugabe@gmail.com</h1>
            </div>

            <div className=" w-1/2 float-left m-1">
            <br/>
            <h1 className="text-lila font-semibold text-left">Ansprechpartner für datenschutzrechtliche Fragen</h1> <br/>
            <h1 className=" ">Bei Fragen zum Datenschutz stehen wir unter fsj.zugabe@gmail.com oder unter der 
            oben angegebenen postalischen Anschrift zur Verfügung. Wendet euch da bitte an Adrian Rosengarten.</h1> <br/>
            </div>

            <h1 className=" clear-left font-semibold text-lila">Datenverarbeitung im Verein</h1> <br/>
            
            <h1>Mitgliedsdaten (persönliche Angaben) werden von den jeweiligen Funktionsträger*innen unseres Vereins nur für
                die ihnen zugeordnete Aufgabenerfüllung verarbeitet. Im Einzelnen bedeutet dies:</h1> <br/>

            <div className="sm:ml-12">
                <h1>Wenn der Vorstand Mitgliedsdaten benötigt, um seine Aufgaben zu erledigen, darf er auf alle hierfür 
                    erforderlichen Mitgliedsdaten zugreifen.</h1> <br/>

                <h1>Der*die Kassenwart*in verarbeitet die Mitgliedsdaten, die für den Einzug der Mitgliedsbeiträge, 
                    der*die Kassenprüfer*in verarbeitet die Mitgliedsdaten, die für die Kassenprüfung relevant sind. Dies 
                    sind Vorname, Nachname, postalische Anschrift und Bankverbindung mit Zahlungsdaten sowie ggf. Zugriff 
                    auf die Lastschriftsverfahrensgenehmigung inklusive Unterschrift, sofern das Mitglied dem Verein ein 
                    Lastschriftmandat erteilt hat.</h1> <br/>

                <h1>Zweck für die Verarbeitung der Mitgliedsdaten ist die Verfolgung des Vereinszwecks und die 
                    -verwaltung. Rechtsgrundlage ist die Vereinszugehörigkeit (Artikel 6 Absatz 1 b) DS-GVO).</h1>
            </div> <br/>

            <h1 className="font-semibold text-lila">Speicherdauer</h1> <br/>

            <h1>Die aktuellen Mitgliedsdaten werden für die Dauer der Mitgliedschaft und darüber hinaus für 2 Jahre 
                gespeichert.</h1> <br/>

            <h1>Im Falle des Widerrufs der Einwilligung werden die Daten unverzüglich gelöscht.</h1>

        </div>
        
        <Footer/>
        </div>
    );
}
import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/img/Zugabe-eV-Logo.png";

export default function LogoHead(){
    return(
      <div className=" flex justify-left items-center">
        <Link to='/home'><img src={logo} className="h-auto w-auto max-w-96"/></Link>
    </div>
    );
}
import React from "react";
import { Link } from "react-router-dom";

export default function Event(props){
    return(
        <div className='container'>
            
            <div className='eventTitle'>    
                <button className="m-4"> 
                <Link to={props.link} onClick={toTop} className=' text-xs text-lila bg-white font-bold px-4 py-2 my-1 rounded-lg  border-lila border-2 border-l-4 border-b-4 hover:bg-lila hover:text-white'>
                    {props.title}
                </Link>
                </button>
            </div>

<div className='grid grid-cols-2 gap-4 '>

            <div className='eventDate'>
                <div className='my-2 rounded-full text-hellblau font-bold text-s '>
                {props.date}
                </div>
            </div>

            <div className='eventTime'>
                <div className='my-2 rounded-full text-hellblau font-bold text-s '>
                {props.time}
                </div>
            </div>
</div>

<div className='grid grid-cols-2 gap-4 '>
            <div className='eventLocation'>
                <div className=' px-1 py-1  mx-1 my-1 rounded-full  text-lila font-bold text-xs'>
                {props.location}
                </div>
            </div>
        
            <div className='eventPlatform'>
                <div className='px-1 py-1  mx-1 my-1 rounded-full  text-lila font-bold text-xs'>
                {props.platform}
                </div>
            </div>
        
</div>

                <div className='eventTeaser'>
                <p className='mt-2 text-sm'>{props.teaser}</p>
                </div>
                


            
        
 </div>
    )
}

const toTop = () => {
    window.scrollTo(0, 0);
  };
import React from "react";
import '../App.css';
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import LogoHead from "../components/logoHead";

export default function Eventpage(props){
    return(
        <div>
            <LogoHead/>
            <Navbar/>
            <div className=" text-left font-montserrat m-4 ml-10 mr-10 p-8 rounded-3xl border-4 border-lila">

                <h1 className="flex max-w-xl text-4xl text-white font-bold px-4 py-2 my-1 rounded-lg bg-gradient-to-r from-lila to-pink">{props.title}</h1> <br/>
                <br/>
                <p className="text-lila font-bold">{props.date} {props.time}</p>
                <p className="text-lila font-bold">{props.location} {props.platform}</p> 
                <p>{props.description}</p>
            </div>
            <Footer/>
        </div>
    )
}
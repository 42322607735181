import React from "react";
import { Link } from "react-router-dom";

export default function Footer(){
    return(
        <div className='font-montserrat text-xs md:text-base w-full h-60 pt-6 bg-bggrau mt-20 border-t-4 border-lila'>
          <div className="grid flex-wrap items-center font-bold grid-cols-3">
<div className="col 1 ZUGABE text-lila">
          <Link to='/machmit'  onClick={toTop}>
            <h1 className=" hover:underline mb-4">Mitglied werden</h1>
          </Link>

            <Link to='mailto:fsj.zugabe@gmail.com' className="hover:underline">fsj.zugabe@gmail.com
          </Link>
</div>

<div className="col 2 Fundus text-pink">
          <Link to='https://freiwilligen-fundus.de/' onClick={toTop}>
            <h1 className=" hover:underline">Zum Fundus</h1>
          </Link> 

          <Link to='https://www.betterplace.org/de/projects/108762-die-freiwilligendienste-inklusiver-und-diverser-machen-mit-dem-fundus'  onClick={toTop}>
            <h1 className=" hover:underline mt-4">Fundus spenden</h1>
          </Link>
</div>

<div className="col 3 Rechtliches text-lila">
            <Link to='/impressum'>
            <h1 className="hover:underline" onClick={toTop} >Impressum</h1>
          </Link>
          <Link to='/datenschutz'>
            <h1 className="hover:underline mt-4" onClick={toTop}>Datenschutz</h1>
          </Link>
          </div>

          </div>
          </div>
    );
}


const toTop = () => {
  window.scrollTo(0, 0);
};
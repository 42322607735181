import React from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import LogoHead from "../components/logoHead";
import { Link } from "react-router-dom";


export default function NotFound(){
    return(
        <div>
            <LogoHead/>
            <Navbar/>
            <div className="font-montserrat text-center mt-11">
                <h1 className="text-lila font-bold text-9xl">#404</h1>
                <img className="mx-auto" src="https://64.media.tumblr.com/808773aec29c6ff09a14f600629ec75a/tumblr_nsikigSfsd1rbf5cro2_500.gif" alt="Ameisen"/>
                <a className="text-slate-300 text-sm" href="https://nowthisnews.tumblr.com/post/125778684179/the-power-of-ants">Quelle: https://nowthisnews.tumblr.com/post/125778684179/the-power-of-ants</a>
                <p className="mt-6 text-xl">Ups, anscheinend existiert diese Seite nicht.</p>
                <Link to='/home' className="text-s float-center text-white font-montserrat font-bold px-4 py-2 mt-3  inline-block rounded-lg bg-hellblau hover:bg-gradient-to-r hover:underline from-lila to-pink">Zurück zur Startseite</Link>
            </div>
            <Footer/>
        </div>
    )
}
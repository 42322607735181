import React from "react";

export default function Sind(props) {
    return (
<div className="container items-center max-w-xs hover:bg-slate-50 rounded-lg">

        <div className='AG Titel flex justify-center items-center'>
            <div className=' max-w-xl text-white font-bold px-4 py-2 my-1 rounded-lg bg-gradient-to-r from-lila to-pink'>
            {props.kanal}
            </div>
        </div>

        <div className='AG Kontakte grid'>
            <div className='px-1 py-1  mx-1 my-1 rounded-full  text-lila font-bold text-xs'>
            {props.kontakt}
            </div>
        </div>

        <div className='AG Aufgaben'>
            <p className='mt-2'>{props.aufgaben}</p>
        </div>

               
            
</div>


    );
}

import React from "react";
import '../App.css';
import Navbar from "../components/navbar";
import LogoHead from "../components/logoHead";
import Footer from "../components/footer";
import Anmeldung from "../components/anmeldebtn.js"
import WirSind from "../components/wer-und-ags/wirsindCard.js";
import { finanz, vorstand, werwirsindtext } from "../assets/textcontent/werwirsind";


export default function WerWirSind(){
    return(
        <div>
        <LogoHead/>
        <Navbar/>
        <div className=" m-4 sm:ml-10 sm:mr-10 p-8 rounded-3xl border-0 border-lila font-montserrat "> 
        
    <div className="flex flex-col md:flex-row">
        <div className="Werwirsind md:w-1/2">
            <h2 className="text-lila font-bold my-5 text-2xl">Wer wir sind</h2>
            <p className="flex">{werwirsindtext}</p>
            
            
            <button>
            <a href="/Info" className="text-s text-lila font-bold px-4 py-2 my-4 inline-block rounded-lg border-lila border-2 border-l-4 border-b-4 hover:bg-lila hover:text-white">
                Discord »</a>
            </button>
        </div>

        <div className="Kontakte md:w-1/2">
        <h2 className="text-lila font-bold my-5 text-2xl">Kontaktpersonen</h2> 
            <p>Vorstand: {vorstand}</p>
            <p>Revision und Finanzen: {finanz}</p>
            <p>EasyVerein: Chris, Johanna</p>
            <Anmeldung/>
        </div>
    </div>

        <div>
        <h2 className="text-lila font-bold my-5 text-2xl">AG's</h2> 
        <WirSind/>
        </div>
        
        

</div>
         

        <Footer/>
        </div>
    );
}
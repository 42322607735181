export const kulturtischtext = 
"Als deutschlandweiter Verein findet unser Austausch über Discord statt. Der Kulturtisch wurde deshalb ins Leben gerufen, um sich besser kennenzulernen. Als on- und offline Stammtisch lässt sich das Event ganz flexibel gestalten. Du willst neue Leute in deiner Umgebung kennenlernen oder hast Lust Deine Gruppe aus alten FSJ-Zeiten wieder zusammenzubringen? Schließ dich dem nächsten Termin an oder initiiere deinen eigenen Kulturtisch! Melde dich dazu per Mail oder im #programm Kanal bei Johanna.";

export const Kulturtischinfos = [
    {
        date:"16.3.2024",
        time:"15 Uhr",
        title: "Kulturtisch",
        teaser: "Unser erster offline Kulturtisch!",
        description:"Unser erster offline Kulturtisch findet statt! Noch steht kein Programm fest.",
        location:"Osnabrück",
        platform:"offline",
    },
    
    {
        date:"2.4.2024",
        time:"18 Uhr",
        title: "Kulturtisch",
        teaser: "Umgezogen, aber kennst niemanden?",
        description:"Du bist neu in der Stadt oder hast Lust einfach mal in den Verein zu schnuppern? Komm gerne vorbei, du musst kein Mitglied sein!",
        location:"Discord",
        platform:"online",
    },

    {
        date:"7.5.2024",
        time:"20 Uhr",
        title: "Kulturtisch",
        teaser: "Offene Gesprächsrunde",
        description:"Du bist neu in der Stadt oder hast Lust einfach mal in den Verein zu schnuppern? Komm gerne vorbei, du musst kein Mitglied sein!",
        location:"Discord",
        platform:"online",
    },
]

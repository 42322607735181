
import React from 'react';
import Eventcard from './Eventcard';
import { Kulturtischinfos } from "../../assets/textcontent/contentkulturtisch";


export default function Nextevent (){ 
  return (
    
    <div className="font-montserrat grid ">
      
      <ul className='flex flex-wrap justify-center items-center'> 
       {Kulturtischinfos.map((eventcard, index) => (
        <li key={index} className='max-w-xs w-64'>
            <Eventcard {...eventcard}/>
        </li>
       ))}  
      </ul>
    </div>

  )
}

